import React from 'react';
import { Canvas } from '@react-three/fiber';
import Stars from './Stars';
import Planet from './Planet';

export default function Background() {
  const planets = [
    { count: 25, radius: 7, color: "#b02904" }, // orange
    { count: 50, radius: 5, color: "#02ddfa", size: 0.10 },  // blue
    { count: 25, radius: 8, color: "#b01c1c" }, // red
    { count: 50, radius: 7, color: "yellow" },
  ]
  return (
    <Canvas camera={{ position: [0, 0, 1] }}>
      <ambientLight />
      <pointLight position={[10, 10, 10]} />
      <Stars rotationDenominator={{ x: 100, y: 100, z: 100 }} />
      {planets.map((planet, index) => (
        <Planet key={index} {...planet} rotationDenominator={{ x: 100, y: 100, z: 100 }} />
      ))}
    </Canvas>
  );
}
