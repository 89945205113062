import React from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Points, PointMaterial } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";
import * as THREE from "three";

function Stars(props) {
  const ref = React.useRef();
  const mouseRef = React.useRef(new THREE.Vector2());
  const raycaster = React.useRef(new THREE.Raycaster());
  const originalPositions = React.useRef();
  const targetPositions = React.useRef();
  const { camera, size } = useThree();

  const [sphere] = React.useState(() => {
    const positions = random.inSphere(new Float32Array(5000), { radius: 1.5 });
    originalPositions.current = positions.slice();
    targetPositions.current = positions.slice();
    return positions;
  });

  React.useEffect(() => {
    const handleMouseMove = (event) => {
      mouseRef.current.x = (event.clientX / size.width) * 2 - 1;
      mouseRef.current.y = -(event.clientY / size.height) * 2 + 1;
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, [size]);

  useFrame((state, delta) => {
    if (!ref.current) return;

    // Update rotation
    ref.current.rotation.x -= delta / props.rotationDenominator.x;
    ref.current.rotation.y -= delta / props.rotationDenominator.y;
    ref.current.rotation.z -= delta / props.rotationDenominator.z;

    // Update raycaster
    raycaster.current.setFromCamera(mouseRef.current, camera);

    // Calculate intersections and update positions
    const positions = ref.current.geometry.attributes.position.array;
    const maxDistance = 0.1; // Maximum distance for effect
    const pushStrength = 0.1; // How far stars move away

    for (let i = 0; i < positions.length; i += 3) {
      const point = new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]);
      const worldPoint = ref.current.localToWorld(point.clone());
      
      // Calculate distance to ray
      const distance = raycaster.current.ray.distanceToPoint(worldPoint);
      
      if (distance < maxDistance) {
        // Calculate push direction (away from ray)
        const pushDirection = worldPoint.sub(raycaster.current.ray.origin).normalize();
        const pushAmount = (1 - distance / maxDistance) * pushStrength;
        
        // Update target position
        targetPositions.current[i] = originalPositions.current[i] + pushDirection.x * pushAmount;
        targetPositions.current[i + 1] = originalPositions.current[i + 1] + pushDirection.y * pushAmount;
        targetPositions.current[i + 2] = originalPositions.current[i + 2] + pushDirection.z * pushAmount;
      } else {
        // Return to original position
        targetPositions.current[i] = originalPositions.current[i];
        targetPositions.current[i + 1] = originalPositions.current[i + 1];
        targetPositions.current[i + 2] = originalPositions.current[i + 2];
      }

      // Smooth interpolation to target position
      positions[i] += (targetPositions.current[i] - positions[i]) * 0.1;
      positions[i + 1] += (targetPositions.current[i + 1] - positions[i + 1]) * 0.1;
      positions[i + 2] += (targetPositions.current[i + 2] - positions[i + 2]) * 0.1;
    }

    ref.current.geometry.attributes.position.needsUpdate = true;
  });

  return (
    <group
      rotation={[0, 0, Math.PI / 4]}
    >
      <Points
        ref={ref}
        positions={sphere}
        stride={3}
        frustumCulled={false}
        {...props}
      >
        <PointMaterial
          transparent
          color={"white"}
          size={0.005}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

export default Stars;
