import React from "react";
import { useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import * as THREE from "three";

function GlassCard({ html, position, isActive, ...props }) {
  const mesh = React.useRef();
  const targetPosition = React.useRef(new THREE.Vector3(...position));

  useFrame((state, delta) => {
    if (!mesh.current) return;

    // Update target position
    targetPosition.current.set(...position);

    // Smooth transition to target position
    mesh.current.position.lerp(targetPosition.current, 0.1);
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={0.75}
    >
      <boxGeometry
        args={[1.5, 1, 0.0]}
        attach="geometry"
        castShadow={true}
        receiveShadow={true}
      />
      <meshStandardMaterial
        color={"#dbedff"}
        transparent={true}
        opacity={isActive ? 0.1 : 0.05}
        roughness={0.7}
        metalness={0.7}
      />
      <Html
        style={{
          transition: "all 0.2s",
          transform: `scale(0.5)`,
          textAlign: "left",
          fontFamily: "monospace",
          fontWeight: "bold",
          color: "white",
          textShadow: "0 0 20px #8993ff",
          fontSize: "1.5rem",
          height: "500px",
          width: "600px",
          maxWidth: "600px",
          maxHeight: "600px",
          overflowY: "scroll",
          zIndex: "10",
          paddingLeft: "10px",
          opacity: isActive ? 1 : 0.3,
          pointerEvents: isActive ? "auto" : "none",
          touchAction: "pan-y",
          userSelect: "text",
          WebkitOverflowScrolling: "touch",
          position: "relative",
          isolation: "isolate"
        }}
        distanceFactor={1.0}
        transform
        position={[0, 0, 0.51]}
        occlude
        zIndexRange={[0, 100]}
        portal={document.body}
      >
        {html}
      </Html>
    </mesh>
  );
}

export default GlassCard;
